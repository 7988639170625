
import Logger from '../logger.js';
import SERVICE_URL from "../../config/API_DATA"
import HttpPost from "../../http/httppost.js";
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
export default class DeleteImage
{
    static buildRequest (pImagepath)
    {
        let returnValue = null;
        try {
            let body = {
                path: pImagepath
            }
            returnValue = body;
        }
        catch (err)
        {
            let body = Logger.buildRequest( FILE_NAME.API_DELETE_IMAGE , "error in building body for DeleteImage api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.API_DELETE_IMAGE, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_DELETE_IMAGE , "error in getting Response from  DeleteImage api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}