import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiCreatePostDetails {
    static buildRequest(value) {
        let returnValue = null;
        try {
            if (!value) throw new Error('Error value not found')
            let query = gql `mutation insert_diy_project {
                insert_diy_project_project(objects:
                  {
                    title: "${value.title}", 
                    field_of_work: "${value.field_of_work}", 
                    duration: "${value.duration}", 
                    description: "${value.description}",
                    isvideo: ${value.isvideo},
                    link: "${value.link}",
                    tags:"${value.tags}",
                    mainimage: "${value.mainimage}",
                    required_items: "${value.required_items}", 
                    timeinmins: ${value.timeinmins},
                    categoryid: ${value.categoryid},
                    status: ${value.status},
                    author: "${value.author}",
                    groupid: ${value.groupid}
                  },
                  on_conflict: {
                      constraint: project_pkey
                      update_columns: [title, field_of_work, duration, description, isvideo, link, mainimage, required_items, timeinmins, categoryid, status, author]
                  }
                ){
                  returning{
                    id
                  }
                }
              }
              `;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_DIY_POST, "error in building query for CreateDiyPostDetails api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.GET_ALL_DIY_POST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_DIY_POST, "error in getting Response from CreateDiyPostDetails api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}