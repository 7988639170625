import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import gql from "graphql-tag";
import Apollo from "../../graphql/ApolloClient";

export default class ApiDIYGetDiyProjectItem {
    static buildRequest(id) {
        let returnValue = null;
        try {
            let query = gql`query 
            { diy_project_project_items 
                {
                itemid
                item_name
                projectid
                qty
                unit
                project_item_links {
                  ecom_link
                  ecom_source
                  itemid
                  linkid
                  countryid
                }
              }
            }`;

            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_PROJECT_ITEM, "error in building body for ApiDIYGetDiyProjectItem api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.GET_ALL_DIY_PROJECT_ITEM, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_PROJECT_ITEM, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}