<template>
  <div>
    <CCard>
      <ValidationObserver v-slot="{ handleSubmit }">
        <CForm
          autocomplete="off"
        >
          <CCardHeader class="grey-header">
            <CRow >
              <CCol col="9">
                <strong>{{
                  isUserUpdate
                    ? $t("addDiyPost.updateTitle")
                    : $t("addDiyPost.addTitle")
                }}</strong>
              </CCol>

               <CCol col="3">
                <strong>{{ post.status==POST_STATUS.DRAFTED ? 'Drafted' :
                          post.status==POST_STATUS.PENDING ? 'Pending' :
                          post.status==POST_STATUS.PUBLISHED ? 'Published' :
                          'Unpublished'}}</strong>
              </CCol>

              <p class="desc" v-if="isUserUpdate">
                {{ $t("addDiyPost.updateDesc") }}
              </p>
              <p class="desc" v-else>{{ $t("addDiyPost.addDesc") }}</p>
            </CRow>
          </CCardHeader>
          <CCardBody >
           <CRow>
             <CCol md="7">
                <label><strong>{{$t('addDiyPost.title')}}</strong></label>
               <cTextInputComponent
                  v-model="post.title"
                  :placeholder="$t('addDiyPost.title')"
                  :name="$t('addDiyPost.title')"
                  rules="required"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                />
                <label><strong>{{$t('addDiyPost.description')}}</strong></label>
                <cRichTextEditor
                        v-model="post.description"
                        :selValue="post.description"
                        @getEditorText="onDesEditorChange"
                      />

                <!-- <label><strong>{{$t('addDiyPost.requirement')}}</strong></label>
                <cTextInputComponent
                  v-model="post.requirement"
                  :placeholder="$t('addDiyPost.requirement')"
                  :name="$t('addDiyPost.requirement')"
                  rules="required"
                  :isAutoComplete="false"
                  @onInput="onEdit"
                /> -->

                <!-- <label><strong>{{$t('addDiyPost.videoLink')}}</strong></label> -->
                <cTextInputComponent
                  :label="$t('addDiyPost.videoLink')"
                  v-model="post.videolink"
                  :placeholder="$t('addDiyPost.videoLink')"
                  :name="$t('addDiyPost.videoLink')"
                  :isAutoComplete="false"
                  @onInput="onVideoLinkEdit"
                />

                <!-- <label><strong>{{$t('addDiyPost.readMore')}}</strong></label> -->
                 <cTextInputComponent
                 :label="$t('addDiyPost.readMore')"
                  v-model="post.readmorelink"
                   :rules="'required'"
                  :placeholder="$t('addDiyPost.readMore')"
                  :name="$t('addDiyPost.readMore')"
                  :isAutoComplete="false"
                  @onInput="onLinkEdit"
                />
             </CCol>
             <CCol>

               <label><strong>{{$t('addDiyPost.group')}}</strong></label>
                <cDropDown
                  v-on:input="onGroupChange"
                  :selValue="post.group"
                  :ctrlName="'description'"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isSearchable="true"
                  :url="'diyGroup'"
                  rules="required"
                />
                
                <label><strong>{{$t('addDiyPost.category')}}</strong></label>
             
                <cDropDown v-if="post.group!=null && post.group.groupid!=null && showCategory"
                  v-on:input="onCategoryChange"
                  :selValue="post.categoryid"
                  :ctrlName="'categoryname'"
                  :isCategory="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isSearchable="true"
                  :groupId="post.group.groupid"
                  :isTaggable="true"
                  :isAllowEmpty="true"
                  :url="'diyCategory'"
                  rules="required"
                />

                <label><strong>FIELD OF WORK</strong></label>
             
                <cDropDown v-if="post.group!=null && post.group.groupid!=null && showCategory"
                  v-on:input="onTagChange"
                  :selValue="post.tag"
                  :ctrlName="'field_of_work'"
                  :isFieldOfWrk="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isSearchable="true"
                  :groupId="post.group.groupid"
                  :isTaggable="true"
                  :isAllowEmpty="true"
                  :url="'diyTags'"
                  rules="required"
                  
                />

                <label><strong>TAGS</strong></label>
             
                <cDropDown 
                  v-on:input="onTagsChange"
                  :selValue="post.tagsLs"
                  :isAllowEmpty="true"
                  :isShowCode="false"
                  :isMultiple="true"
                  :isTaggable="true"
                  :isSearchable="true"
                 :ctrlName="'label'"
                  :url="'diyTagsList'"
                  rules="required"
                  
                />
                  <label><strong>PROJECT TIME</strong></label>
                  
                      <ValidationProvider :rules="'required'" v-slot="{ errors }">
                       <!-- <CRow class="mb-20px" v-model="post.projecttime"> -->
                    <vue-timepicker
                      ref="arrTimePick"
                      class="input-normal w-100"
                      v-model="post.projecttime"
                      
                      :format="DATE_TIME_FORMAT.DEFAULT_TIME_FORMAT"
                      :minute-interval="5"
                      close-on-complete
                      hide-clear-button
                      @open="toggleTimePickAnimClass('arrTimePick')"
                      @close="toggleTimePickAnimClass('arrTimePick')"
                    />
                    <div v-if="errors && errors[0]" class="invalid-feedback icon">
                                <span>{{ errors[0] }}</span>
                            </div>
                       <!-- </CRow> -->
                        </ValidationProvider>

                <label><strong>IMAGE</strong></label>
                <ValidationProvider :rules="'required'" v-slot="{ errors }">
                <CRow class="filepond-row" v-model="post.imagelink">
                <div >   
                <file-pond
                  @addfile="getImages"
                  name="test"
                  ref="reference"
                  :label-idle="filepondLabel"
                  :allow-multiple="false"
                  :allowBrowse="true"
                  :accepted-file-types="'image/jpeg, image/png'"
                  :allowImagePreview="true"
                  :allowImageTransform="true"
                />
                </div>
                <div v-if="errors && errors[0]" class="invalid-feedback icon">
                                <span>{{ errors[0] }}</span>
                </div>
                 </CRow>
                </ValidationProvider>
                <div v-if="isUserUpdate && post.imagelink">
                  <span v-if="post.imagelink" @click="toDeleteImagePath()" id="close">&times;</span>
                   <img v-if="isValidImage" width="60" height="60" :src="post.imagelink" @error="ImgError"/>
                   <img v-if="!isValidImage" width="60" height="60" src="@imgPath/img/png/defaultImg.png" />
                </div>
            

             <label><strong>{{'AUTHOR'}}</strong></label>
                 <cTextInputComponent
                  v-model="post.author"
                  :placeholder="'Author name'"
                  :name="$t('addDiyPost.readMore')"
                  :rules="'required'"
                  :isAutoComplete="false"
                />

             
               
             </CCol>
      

           </CRow>
            <label><strong>Required Items</strong></label>
            <CCard v-for="(item,index) in projectItemLs" :key="index+'projectitem'">
                <CRow >
                
                  <CCol>
                    <label><strong>Item Name</strong></label>
            
                <cDropDown v-if="GET_PROJECT_ITEM_LS"
                  v-on:input="onItemNameChange($event,index)"
                  :selValue="isUserUpdate?item.item_name:item"
                  :ctrlName="'item_name'"
                  :isAllowEmpty="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isTaggable="true"
                  :isSearchable="true"
                  :isItemName="true"
                  :url="'diyItemList'"
                  rules="required"
                 />
                  </CCol>
                 <CCol>
                    <!-- <label><strong>Quantity</strong></label> -->
             
                 <cTextInputComponent
                  label="Quantity"
                  v-model="item.qty"
                  :placeholder="'Quantity'"
                  :name="$t('addDiyPost.readMore')"
                  :rules="'required'"
                  :isAutoComplete="false"
                />
                  </CCol>
                    <CCol>
                       <label><strong>Units</strong></label>
                       
                <cDropDown
                  v-on:input="onUnitChange($event,index)"
                  :selValue="item.unit"
                   :ctrlName="'name'"
                  :isAllowEmpty="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isTaggable="true"
                  :isSearchable="true"
                 
                  :url="'diyItemUnit'"
                  rules="required"
                 />
                  </CCol>
                  <CCol>
                       <!-- <label><strong>Ecommerce Link</strong></label> -->
             
              <cTextInputComponent
                  label="Ecommerce Link"
                  v-model="item.project_item_links.ecom_link"
                  :placeholder="'Ecommerce Links'"
                  :name="$t('addDiyPost.readMore')"
                  :rules="'required'"
                  :isAutoComplete="false"
                />
                  </CCol>
                  <CCol>
                       <label><strong>Ecommerce Source</strong></label>
             
                <cDropDown v-if="GET_PROJECT_ITEM_LS"
                  v-on:input="onSourceChange($event,index)"
                  :selValue="item.project_item_links.ecom_source"
                  :isAllowEmpty="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isTaggable="true"
                  :isSearchable="true"
                 :ctrlName="'name'"
                  :url="'diyEcomSource'"
                  rules="required"
                 />
                  </CCol>
                 <CCol>
                       <label><strong>Country</strong></label>
             
                <cDropDown 
                  v-on:input="onCountryChange($event,index)"
                  :selValue="item.project_item_links.country"
                  :ctrlName="'value'"
                  :isAllowEmpty="true"
                  :isShowCode="false"
                  :isMultiple="false"
                  :isTaggable="true"
                  :isSearchable="true"
                 
                  :url="'diyCountryLs'"
                  rules="required"
                 />
                  </CCol> 
                  <CCol>
                    <label><strong>Delete</strong></label>
                 
                            <CButton color="danger" class="action-c-btn" @click.prevent="removeProjectItem(index)">
                          Remove
                        </CButton>
                  </CCol>
                  <CCol v-if="index==projectItemLs.length-1">
                       <label><strong>Create</strong></label>
                       <div @click="addAnItemList()">
             <!-- <CIcon style="fill:#c7bca9" name="cil-settings" size="lg" /> -->
              <CButton color="success" class="action-c-btn">
                ADD
              </CButton>
               </div>
                  </CCol> 
                </CRow>

                
                </CCard>

                 <CRow>
                  <CCol>
                    <CButton v-if="post && post.hasOwnProperty('status') && post.status!=null?post.status==POST_STATUS.DRAFTED:true"
               type="submit"
                color="danger"
                class="action-c-btn"
                @click.prevent="handleSubmit(onDrafted)"
              >
                {{ 'SAVE DRAFT' }}
              </CButton>
               <CButton v-if="post && post.hasOwnProperty('status') && post.status!=null?
                        (post.status==POST_STATUS.PENDING||post.status==POST_STATUS.REJECT):false"
               type="submit"
                color="danger"
                class="action-c-btn"
                @click.prevent="handleSubmit(onReject)"
              >
                {{ 'REJECT' }}
              </CButton>
                  </CCol>

                   <CButton v-if="post?post.status==POST_STATUS.PUBLISHED:false"
                col="12"
                type="submit"
                color="success removewrap"
                class="action-c-btn"
                @click.prevent="handleSubmit(onPublishUpdate)"
              >
                {{'SAVE'}}
              </CButton>
                  <CCol>
                   
               <CButton v-if="post && post.hasOwnProperty('status') && post.status!=null?
                        (post.status==POST_STATUS.DRAFTED ||post.status==POST_STATUS.UNPUBLISHED  ):true"
                col="12"
                type="submit"
                color="success removewrap"
                class="action-c-btn"
                @click.prevent="handleSubmit(onAddUserFormSubmit)"
              >
              {{'SUBMIT'}}
              </CButton>

              <CButton v-if="post && post.hasOwnProperty('status') && post.status!=null?
                        (post.status==POST_STATUS.PENDING||post.status==POST_STATUS.REJECT):false"
               type="submit"
                color="success removewrap"
                class="action-c-btn"
                @click.prevent="handleSubmit(onAddUserFormSubmit)"
              >
                {{ 'APPROVE' }}
              </CButton>
              
              
              
              <CButton v-if="post?post.status==POST_STATUS.PUBLISHED:false"
                col="12"
                type="submit"
                color="success removewrap"
                class="action-c-btn"
                @click.prevent="handleSubmit(onUnpublishThePost)"
              >
                
                {{'UNPUBLISH'}}
              </CButton>
              
                  </CCol>
                </CRow>
         
           <CAlert v-if="alertValue" show color="danger">
                <div
                  v-if="alertValue && alertValue.hasOwnProperty('valueList')"
                >
                  <p
                    v-for="(value, index) in alertValue.valueList"
                    :key="index"
                  >
                    {{ value }}
                  </p>
                </div>
                <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
                  <span>{{ $t("cAlert.errorCode") + " : " }}</span
                  ><span
                    v-for="(code, index) in alertValue.codeList"
                    :key="index"
                  >
                    {{ code + " " }}
                  </span>
                </div>
                <div v-if="alertValue && alertValue.constructor == String">
                  <p>
                    {{ alertValue }}
                  </p>
                </div>
              </CAlert>
          </CCardBody>

        </CForm>
      </ValidationObserver>
    </CCard>
    <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
  </div>
</template>

<script>
import Vue from "vue";
import BaseConfiguration from "../../config/base";
import cDropDown from "@/components/form/cDropDown";
import { goBack, updateDataEdited } from "@/router/index";
import { ValidationObserver } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import cTextInputComponent from "@/components/form/cTextInputComponent";
import cAlertModal from "./../../components/form/cAlertModal.vue";
import { COMPONENT_NAME, ALERT_MODAL_STATUS, DATE_TIME_FORMAT, POST_STATUS, ITEM_UNITS, ITEM_ECOM} from "./../../util/constants";
import apiCreateUser from "./../../services/apiCreateUser";
import ApiCreatePostDetails from "./../../services/diy/apiCreatePost";
import ApiUpdateDiyPostDetails from "./../../services/diy/apiUpdatepost";
import ApiDiyPostDetailsById from "./../../services/diy/apiGetpostDetailsByid";
import ApiCreateProjectItem from "./../../services/diy/apiCreateProjectItem";
import ApiUpdateProjectItem from './../../services/diy/apiUpdateProjectItem';
import ApiDIYGetCountryLs from './../../services/diy/apiGetCountryLs'
import ApiUpdateProjectItemLinks from './../../services/diy/apiUpdateProjectItemLinks'
import Logger from "./../../services/logger";
import { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
  VALIDATE_GL_SUCCESS_RES,
} from "./../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  cloneDeep,
} from "../../util/util";
import cRichTextEditor from "../../components/form/cRichTextEditor";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import UploadImage from "./../../services/diy/apiUploadImage";
import DeleteImage from "./../../services/diy/apiDeleteImage";
    import {
        ValidationProvider
    } from "vee-validate";
import ApiDIYGetDiyProjectItem from '../../services/diy/apiGetProjectItems'
// import { delete } from 'vue/types/umd';
    var moment = require("moment");

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);
export default {
  name: COMPONENT_NAME.ADD_USER,
  components: {
    ValidationObserver,
    cTextInputComponent,
    cRichTextEditor,
    cAlertModal,
    cDropDown,
    VueTimepicker,
    FilePond,
    ValidationProvider,
  },

  data: () => {
    return {
      POST_STATUS:POST_STATUS,
      showCategory:true,
      selectedGrp: null,
      post: {
        title: null,
        status:null,
        author: null,
        group: null,
        categoryid: null,
        description: null,
        tag: null,
        tagsLs:[],
        requirement: null,
        videolink: null,
        imagelink: null,
        readmorelink: null,
        projecttime: null,
        id: null,
        duration: null,
        
      },
      projectItemLs: [],
      projectItem:{
          item_name: null,
          unit:null,
          qty: null,
          project_item_links: {
                    ecom_link:null,
                    ecom_source:null,
                    country: {
                      id:null,
                      calling_code:null,
                      value:null
                    }
                  }
        },
     isValidImage:true,
      filepondLabel:
        'Drop files here or <span class="filepond--label-action">Browse</span>',
      isUserUpdate: false,
      alertValue: null,
      showAlertModal: false,
      modalStatus: null,
      buttonList: [],
      confirmText: null,
      imageLinkObj:null
    };
  },

  methods: {
    ...mapActions("sAddDiyPost", {
      SET_CREATE_POST_RESPONSE: "SET_CREATE_POST_RESPONSE",
      SET_CREATE_USER_REQUEST: "SET_CREATE_POST_REQUEST",
      SET_UPDATE_POST_RESPONSE: "SET_UPDATE_POST_RESPONSE",
      SET_UPDATE_POST_REQUEST: "SET_UPDATE_POST_REQUEST",
      CLEAR_ADD_POST_STORE: "CLEAR_ADD_POST_STORE",
    }),
    ...mapActions("sManageDiyPost", {
      CLEAR_MANAGE_POST_STORE: "CLEAR_MANAGE_POST_STORE",
    }),
    ...mapActions("sManageDiyPost", ["SET_PROJECT_ITEM_LS"]),
    ...mapActions("sManageDiyPost", ["SET_COUNTRY_LS"]),

     ImgError(){
            this.isValidImage = false;
            // this.defaultImgSrc = '@imgPath/img/png/defaultImg.png'
        }, 

     onDrafted() {
      try {
      //  this.post.status = POST_STATUS.PENDING;
       this.showModal(ALERT_MODAL_STATUS.INFO,POST_STATUS.DRAFTED);
      // this.onAddUserFormSubmit();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onDrafted",
          err.toString(),
          "onDrafted",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

     onPublishUpdate() {
      try {
      //  this.post.status = POST_STATUS.PENDING;
       this.showModal(ALERT_MODAL_STATUS.INFO,POST_STATUS.PUBLISHED);
      // this.onAddUserFormSubmit();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onPublishUpdate",
          err.toString(),
          "onPublishUpdate",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    toggleTimePickAnimClass(pRef) {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleTimePickAnimClass");
        if (
          this.$refs &&
          this.$refs[pRef] &&
          this.$refs[pRef].$el &&
          this.$refs[pRef].$el.childNodes
        ) {
          for (let i = 0; i < this.$refs[pRef].$el.childNodes.length; i++) {
            if (
              this.$refs[pRef].$el.childNodes[i].className &&
              this.$refs[pRef].$el.childNodes[i].className.includes("dropdown")
            ) {
              if (
                this.$refs[pRef].$el.childNodes[i].classList.contains("active")
              ) {
                this.$refs[pRef].$el.childNodes[i].classList.remove("active");
              } else {
                this.$refs[pRef].$el.childNodes[i].classList.add("active");
              }
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.SCHEDULE_NEW,
          "error in toggleTimePickAnimClass",
          err.toString(),
          "toggleTimePickAnimClass",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

   async getImages(error, file) {
     let imageLink = await this.uploadImage(file.getFileEncodeDataURL());
     console.log(imageLink)
     this.imageLinkObj = imageLink.url
      // if(file.getFileEncodeDataURL().substring(23,29)!='null'){
      this.post.imagelink = this.imageLinkObj.base+this.imageLinkObj.path;
       this.isValidImage = null;

            Vue.nextTick(() => {
              ///for a smooth update
              this.isValidImage = true;
            });
    },

    onEdit() {
      try {
        if (BaseConfiguration.isDebug) console.log("onEdit");
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onVideoLinkEdit() {
      try {
        if (BaseConfiguration.isDebug) console.log("onVideoLinkEdit");
        // this.post.readmorelink = null;
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onLinkEdit() {
      try {
        if (BaseConfiguration.isDebug) console.log("onEdit");
        // this.post.videolink = null;
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getModalBtnLs() {
      return [
        {
          name: this.$t("addDiyPost.ok"),
          func: this.navigateToAllUsers,
          color: "success",
        },
      ];
    },
    onDesEditorChange(pDes) {
      if (pDes) {
        this.post.description = pDes;
      }
      updateDataEdited(true);
    },
    onTagChange(pSelectedTag) {
      if (pSelectedTag) {
        this.post.tag = pSelectedTag;
      }
      updateDataEdited(true);
    },
    onCategoryChange(pSelectedCategory) {
      if (pSelectedCategory) {
        // this.post.category = pSelectedCategory
        this.post.categoryid = pSelectedCategory;
      }
      updateDataEdited(true);
    },

    onTagsChange(pSelectedTags) {
      if (pSelectedTags) {
        
        this.post.tagsLs = pSelectedTags;
      }
      updateDataEdited(true);
    },


     onGroupChange(pSelectedCategory) {
      if (pSelectedCategory) {
        this.post.group = pSelectedCategory;
        if(pSelectedCategory.groupid != this.selectedGrp) {
        this.post.categoryid = null;
        this.post.tag = null;
        this.post.tagsLs = [];
        }
        this.showCategory = false;
          let that = this;

          Vue.nextTick(function() {
            that.showCategory = true;
          });
      }
      updateDataEdited(true);
    },

     onItemNameChange(pSelectedCategory,index) {
       let projectItem = cloneDeep(this.projectItemLs)
      if (pSelectedCategory) { 
        ///item id
        if(pSelectedCategory.itemid!=null) {
          projectItem[index]['itemid'] = pSelectedCategory.itemid
        }
        /// item name
       projectItem[index].item_name = pSelectedCategory.item_name;
       ///unit
       if(pSelectedCategory.unit){
          let unitLs = ITEM_UNITS.UNIT_LIST;
          unitLs.filter( unit => {
            if(unit.symbol == pSelectedCategory.unit ) {
              projectItem[index].unit = unit
            }
          })
        }else{
        projectItem[index].unit = pSelectedCategory.unit;
        }
        ///qty
        projectItem[index].qty = pSelectedCategory.qty;

        ///ecom_link
        if(pSelectedCategory.project_item_links && pSelectedCategory.project_item_links.length>0 ) {
        projectItem[index].project_item_links.ecom_link = pSelectedCategory.project_item_links[0].ecom_link
        }else{
           projectItem[index].project_item_links.ecom_link = pSelectedCategory.project_item_links.ecom_link;
        }
                                                       
        

        ///item id
        if(pSelectedCategory.itemid!=null) {
          projectItem[index].project_item_links['itemid'] = pSelectedCategory.itemid
        }

        ///link id
        if(pSelectedCategory.project_item_links && pSelectedCategory.project_item_links.length>0 && pSelectedCategory.project_item_links[0].linkid!=null) {
         projectItem[index].project_item_links['linkid'] = pSelectedCategory.project_item_links[0].linkid
        }else if(pSelectedCategory.project_item_links && pSelectedCategory.project_item_links.linkid!=null){
          projectItem[index].project_item_links['linkid'] = pSelectedCategory.project_item_links.linkid
        }
        ///ecom_source
        if(pSelectedCategory.project_item_links && pSelectedCategory.project_item_links.length>0 && pSelectedCategory.project_item_links[0].ecom_source){
          let sourceLs = ITEM_ECOM.SOURCE_LIST;
          sourceLs.filter( source => {
            if(source.name == pSelectedCategory.project_item_links[0].ecom_source ) {
              projectItem[index].project_item_links.ecom_source = source
            }
          })
        }else{
        projectItem[index].project_item_links.ecom_source = pSelectedCategory.project_item_links.ecom_source;
        }
        ///countryid
        if(pSelectedCategory.project_item_links && pSelectedCategory.project_item_links.length>0 && pSelectedCategory.project_item_links[0].countryid){
          let countryLs = this.GET_COUNTRY_LS;
          countryLs.filter( country => {
            if(country.id == pSelectedCategory.project_item_links[0].countryid ) {
              projectItem[index].project_item_links.country = country
            }
          })
        }else{
        projectItem[index].project_item_links.country = pSelectedCategory.project_item_links.countryid;
        }
        this.projectItemLs = projectItem
      }
      updateDataEdited(true);
    },
     onUnitChange(pSelectedCategory,index) {
      if (pSelectedCategory) {
        this.projectItemLs[index].unit = pSelectedCategory;
      }
      updateDataEdited(true);
    },

    onSourceChange(pSelectedCategory,index) {
      if (pSelectedCategory) {
        this.projectItemLs[index].project_item_links.ecom_source = pSelectedCategory;
      }
      updateDataEdited(true);
    },
    addAnItemList(){
      this.projectItemLs.push(cloneDeep(this.projectItem))
    },

    onCountryChange(pSelectedCategory,index) {
      if (pSelectedCategory) {
        this.projectItemLs[index].project_item_links.country = pSelectedCategory;
      }
      updateDataEdited(true);
    },

    showAlert(value) {
      this.alertValue = value;
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },

    showModal(pStatus,postStatus) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        } else {
          this.modalStatus = this.isUserUpdate
            ? ALERT_MODAL_STATUS.INFO
            : ALERT_MODAL_STATUS.SUCCESS;
        }
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: this.$t("addDiyPost.no"),
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: this.$t("addDiyPost.yes"),
              func: this.onCreateUserFormSubmit,
              color: "success",
              param: postStatus
            },
          ];
          desc = this.$t("addDiyPost.confirmUpdate", {
            fname: this.post.title,
          });
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: this.$t("addDiyPost.ok"),
              func: this.navigateToAllUsers,
              color: "danger",
            },
          ];
          desc = this.isUserUpdate
            ? this.$t("addDiyPost.updateSuccess", {
                fname: this.post.title,
              })
            : this.$t("addDiyPost.addSuccess", {
                fname: this.post.title,
              });
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

     onAddUserFormSubmit() {
      try {
        // this.post.status = POST_STATUS.PUBLISHED;
        this.showModal(ALERT_MODAL_STATUS.INFO,POST_STATUS.PUBLISHED);
      
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onUnpublishThePost",
          err.toString(),
          "onAddUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onReject() {
      try {
        // this.post.status = POST_STATUS.REJECT;
        this.showModal(ALERT_MODAL_STATUS.INFO,POST_STATUS.REJECT);
      
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onReject",
          err.toString(),
          "onReject",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

     onUnpublishThePost() {
      try {
        // this.post.status = POST_STATUS.UNPUBLISHED;
        this.showModal(ALERT_MODAL_STATUS.INFO,POST_STATUS.UNPUBLISHED);
      
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onUnpublishThePost",
          err.toString(),
          "onUnpublishThePost",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

   async onCreateUserFormSubmit(status) {
      try {
          if (this.isUserUpdate) {
            // this.post.status = status;
          let updateUserStatus = await this.getUpdateUserStatus(this.post,status);
          if (updateUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        } else {
          // this.post.status = status;
          // console.log(this.projectItemLs)
          let createUserStatus = await this.getCreateUserStatus(this.post,status);
          if (createUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        }
        updateDataEdited(false);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  onCreateUserFormSubmit",
          err.toString(),
          "onCreateUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

     async getCreateProjectItems(pProjItems) {
      let returnValue;

      try {
        this.showAlertModal = false;
      
        let createUserRequest = ApiCreateProjectItem.buildRequest(pProjItems);

        if (createUserRequest != null) {
         

          let createUserResponse = await ApiCreateProjectItem.getResponse(
            createUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create post Status",
          err.toString(),
          "getCreateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

     async getUpdateProjectItems(pProjItems) {
      let returnValue;

      try {
        this.showAlertModal = false;
      
        let createUserRequest = ApiUpdateProjectItem.buildRequest(pProjItems);

        if (createUserRequest != null) {
         

          let createUserResponse = await ApiUpdateProjectItem.getResponse(
            createUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getUpdateProjectItems",
          err.toString(),
          "getUpdateProjectItems()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },
     async getUpdateProjectLinks(pProjItems) {
      let returnValue;

      try {
        this.showAlertModal = false;
      
        let createUserRequest = ApiUpdateProjectItemLinks.buildRequest(pProjItems);

        if (createUserRequest != null) {
         

          let createUserResponse = await ApiUpdateProjectItemLinks.getResponse(
            createUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in getUpdateProjectLinks",
          err.toString(),
          "getUpdateProjectLinks()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    async invokeCreateProjectItem(post,projItems){
        try {
           let req = {item_name: null,
                    projectid: null,
                    qty: null,
                    unit: null,
                    project_item_links: {
                        data: {
                            countryid: null,
                            ecom_link: null,
                            ecom_source: null
                        }
                        }
           }
           
            req.item_name = projItems.item_name;
            req.projectid = post.id
            req.qty = Number(projItems.qty);
            req.unit = projItems.unit.symbol;
            req.project_item_links.data.countryid = projItems.project_item_links.country.id;
            req.project_item_links.data.ecom_link = projItems.project_item_links.ecom_link;
            req.project_item_links.data.ecom_source = projItems.project_item_links.ecom_source.name;
           await this.getCreateProjectItems(cloneDeep(req))
         
          
        } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in invokeCreateProjectItem",
          err.toString(),
          "invokeCreateProjectItem()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },

    async invokeUpdateProjectItem(post,projItems){
        try {
           let req = {
             itemid:null,
             item_name: null,
                    projectid: null,
                    qty: null,
                    unit: null, 
           }
        
            if(projItems.hasOwnProperty('itemid')) {
            req.itemid = projItems.itemid;
            req.item_name = projItems.item_name;
            req.projectid = this.post.id
            req.qty = Number(projItems.qty);
            req.unit = projItems.unit.symbol;
            
           var projItemObj = await this.getUpdateProjectItems(cloneDeep(req));
            let projLink = {
              itemid:null,
             linkid: null,
                    countryid: null,
                    ecom_link: null,
                    ecom_source: null, 
            }
            projLink.itemid = projItems.itemid;
            projLink.linkid = projItems.project_item_links.linkid;
            projLink.countryid = projItems.project_item_links.country.id;
            projLink.ecom_link = projItems.project_item_links.ecom_link;
            projLink.ecom_source = projItems.project_item_links.ecom_source.name;

            var projLinkObj = await this.getUpdateProjectLinks(cloneDeep(projLink));
            }
       
          
        } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in updateCreateProjectItem",
          err.toString(),
          "updateCreateProjectItem()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },

    async invokeProjectItem(post,isUpdate){
        try {
      
          let projItems = cloneDeep(this.projectItemLs);
          for (let i = 0; i < projItems.length; i++) {
            if(isUpdate) {
              await this.invokeUpdateProjectItem(post,projItems[i])
            }else{
              await this.invokeCreateProjectItem(post,projItems[i])
            }
          }
          
        } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create post Status",
          err.toString(),
          "getCreateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },

    async getCreateUserStatus(pUser,status) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let req = this.validateAndGetPostReqObj(pUser, false,status);
        // console.log(req)
        let createUserRequest = ApiCreatePostDetails.buildRequest(req);

        if (createUserRequest != null) {
          this.SET_CREATE_USER_REQUEST(createUserRequest);

          let createUserResponse = await ApiCreatePostDetails.getResponse(
            createUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data.insert_diy_project_project.returning[0];
            this.showAlert(null);
            this.SET_CREATE_POST_RESPONSE(returnValue);
            await this.invokeProjectItem(returnValue,false)
            this.clearAllUserRes();
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create post Status",
          err.toString(),
          "getCreateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    clearAllUserRes() {
      this.CLEAR_ADD_POST_STORE(null);
      this.CLEAR_MANAGE_POST_STORE(null);
    },
    removeProjectItem(index){
      this.$delete(this.projectItemLs,index)
    },

    validateAndGetPostReqObj(pPost,pIsUpdate,status) {
      let returnValue ;
      var hour = moment("15", "hh").format('hh') 
      var duration = hour.replace(/^0+/, '') + ' hours ' +( (pPost.projecttime.mm.replace(/^0+/, ''))? pPost.projecttime.mm.replace(/^0+/, '')+ ' minutes':'');
      var timeInMinutes =(Number(pPost.projecttime.HH) * 60) + Number(pPost.projecttime.mm) ;
      console.log('{'+pPost.tagsLs.toString()+'}')
      var tagList='{'+pPost.tagsLs.toString()+'}'
    
      if(pIsUpdate) {
      returnValue = {
        id: pPost.id,
        categoryid: pPost.categoryid.categoryid,
        description: pPost.description,
        duration: duration,
        field_of_work: pPost.tag.field_of_work,
        tags:tagList,
        isvideo: pPost.videolink ? true : false,
        link: pPost.videolink || pPost.readmorelink,
        mainimage: pPost.imagelink,
        required_items: pPost.requirement,
        timeinmins: timeInMinutes,
        title: pPost.title,
        status: status,
        author: pPost.author,
        groupid: pPost.group.groupid
      };
      }else{
        returnValue = {
        
        categoryid: pPost.categoryid.categoryid,
        description: pPost.description,
        duration: duration,
        field_of_work: pPost.tag.field_of_work,
        tags:tagList,
        isvideo: pPost.videolink ? true : false,
        link: pPost.videolink || pPost.readmorelink,
        mainimage: pPost.imagelink,
        required_items: pPost.requirement,
        timeinmins: timeInMinutes,
        title: pPost.title,
        status: status,
        author: pPost.author,
        groupid: pPost.group.groupid
      };
      }
      return returnValue;
    },

    async getUpdateUserStatus(pPost,status) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let reqObj = this.validateAndGetPostReqObj(pPost,true,status);
        
        let updateUserRequest = ApiUpdateDiyPostDetails.buildRequest(
          pPost.id,
          reqObj
        );
        
        if (updateUserRequest) {
          let updateUserResponse = await ApiUpdateDiyPostDetails.getResponse(
            updateUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(updateUserResponse)) {
                //succ
                returnValue = updateUserResponse.data;
                this.showAlert(null);
                this.clearAllUserRes();
                await this.invokeProjectItem(returnValue,true)
              } else {
                //err
                let error = getErrorMessage(updateUserResponse);
                let isJwt = handleJWT(error);
                if (!isJwt) {
                  this.showAlert(getErrorAlertValue(error));
                }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Update Post Status",
          err.toString(),
          "getUpdateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },
    navigateToAllUsers() {
      try {
        goBack();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  navigate To All Users",
          err.toString(),
          "navigate To All Users"
        );
        Logger.getResponse(body);
      }
    },
    async getPostDetailsById(id) {
      let returnValue;
      try {
        let getAllDiyPostRequest = ApiDiyPostDetailsById.buildRequest(id);

        if (getAllDiyPostRequest != null) {
          let allPostResponse = await ApiDiyPostDetailsById.getResponse(
            getAllDiyPostRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(allPostResponse)) {
            returnValue = allPostResponse.data.diy_project_project[0];
          } else {
            let error = getErrorMessage(allPostResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  getPostDetailsById",
          err.toString(),
          "getPostDetailsById"
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

      async toDeleteImagePath() {
      try {
        var path = this.post.imagelink.split('diy')[1]
      let deleteImage = await this.deleteImage('/diy'+path);
      if(deleteImage) this.post.imagelink = null;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in toDeleteImagePath",
          err.toString(),
          "toDeleteImagePath",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async uploadImage(pImageUrl) {
      let returnValue;

      try {
        this.showAlertModal = false;
        
        let uploadImageRequest = UploadImage.buildRequest(
          pImageUrl);
        
        if (uploadImageRequest) {
          let uploadImageResponse = await UploadImage.getResponse(
            uploadImageRequest
          );
          if (VALIDATE_SUCCESS_RES(uploadImageResponse)) {
                //succ
                returnValue = uploadImageResponse.data.data?uploadImageResponse.data.data.url:null;
                this.showAlert(null);
                this.clearAllUserRes();
              } else {
                //err
                let error = getErrorMessage(uploadImageResponse);
                let isJwt = handleJWT(error);
                if (!isJwt) {
                  this.showAlert(getErrorAlertValue(error));
                }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in uploadImage",
          err.toString(),
          "uploadImage()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },

    async deleteImage(pImagePath) {
      let returnValue;

      try {
        this.showAlertModal = false;
       
        let deleteImageRequest = DeleteImage.buildRequest(
         pImagePath
        );
        
        if (deleteImageRequest) {
          let deleteImageResponse = await DeleteImage.getResponse(
            deleteImageRequest
          );
          if (VALIDATE_SUCCESS_RES(deleteImageResponse)) {
                //succ
                returnValue = deleteImageResponse.data.data.isDeleted;
                this.showAlert(null);
                this.clearAllUserRes();
              } else {
                //err
                let error = getErrorMessage(deleteImageResponse);
                let isJwt = handleJWT(error);
                if (!isJwt) {
                  this.showAlert(getErrorAlertValue(error));
                }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  deleteImage",
          err.toString(),
          "deleteImage()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },

       async getDiyProjectItems(pProjectId) {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetDiyProjectItem.buildRequest(pProjectId);
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetDiyProjectItem.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_project_items;
            this.SET_PROJECT_ITEM_LS(returnValue);
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getDiyProjectItems",
          err.toString(),
          "getDiyProjectItems",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

     async getDiyCountries() {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetCountryLs.buildRequest();
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetCountryLs.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_countries;
            this.SET_COUNTRY_LS(returnValue)
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getDiyCountries",
          err.toString(),
          "getDiyCountries",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },
  },

  computed: {
    ...mapGetters("sAddDiyPost", {
      selectedPostObj: "GET_SELECTED_POST",
    }),

    ...mapGetters("sSysLogin", { adminSignIn: "GET_ADMIN_SIGNIN_RES" }),
     ...mapGetters("sManageDiyPost", {
      GET_PROJECT_ITEM_LS: "GET_PROJECT_ITEM_LS",
      GET_COUNTRY_LS: "GET_COUNTRY_LS",
    }),
    DATE_TIME_FORMAT() {
      return DATE_TIME_FORMAT;
    },
  },

  async created() {
    try {
      await this.getDiyCountries();
      if (this.selectedPostObj != null && this.selectedPostObj.id) {
        // let post = await this.getPostDetailsById(this.selectedPostObj.id);
        let post = this.selectedPostObj ;
        if (post) {
          this.isUserUpdate = true;
         
          let time = this.$options.filters.convertToHrMin
                (post.timeinmins,"minutes","hh:mm");
              this.selectedGrp = post.groupid; 
          this.post = {
            status:post.status,
            author:post.author,
            title: post.title,
            group: post.groupid,
            categoryid: { categoryname: post.category.categoryname,
             categoryid: post.categoryid},
            description: post.description,
            tag: post.field_of_work,
            tagsLs: post.tags,
            requirement: post.required_items,
            videolink: post.isvideo ? post.link : null,
            imagelink: post.mainimage,
            readmorelink: post.isvideo ? null : post.link,
            projecttime: { HH: time.split(':')[0], mm: time.split(':')[1] },
            duration: post.duration,
            id: post.id,
          };
         var projectItem = await this.getDiyProjectItems(this.post.id);
         if(post.project_items && post.project_items.length!=0) {
         this.projectItemLs = cloneDeep(post.project_items)
         } else{
           this.projectItemLs.push(this.projectItem)
         }
        } 
        // else {
        //   this.isUserUpdate = false;
        // }
      } else {
        var projectItem = await this.getDiyProjectItems(this.post.id);
        this.projectItemLs.push(this.projectItem)
        this.isUserUpdate = false;
      }
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.ADD_USER,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );

      Logger.getResponse(body);
    }
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.ADD_USER);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>

<style>
.input {
  border-radius: 30px;

  width: 300px;
  height: 30px;
}

.filepond-row {
 display: block !important;
}
</style>
