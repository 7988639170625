import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiUpdateProjectItem {
    static buildRequest(value) {
        let returnValue = null;
        try {
            if (!value) throw new Error('Error value not found')
            let query = gql `mutation insert_diy_project {
                update_diy_project_project_items(where: 
                    {itemid: {_eq: ${value.itemid}},
                     projectid: {_eq: ${value.projectid}}},
                      _set: {item_name: ${value.item_name}, projectid: ${value.projectid}, qty: ${value.qty}, unit:  ${value.unit}}) {
                  affected_rows
                  returning {
                    itemid
                  }
                }
              }`;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_PROJECT_ITEM, "error in building query for UpdateProjectItem api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.UPDATE_PROJECT_ITEM, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_PROJECT_ITEM, "error in getting Response from UpdateProjectItem api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}