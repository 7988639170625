import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiCreateProjectItem {
    static buildRequest(value) {
        let returnValue = null;
        try {
            if (!value) throw new Error('Error value not found')
            let query = gql `mutation insert_diy_project {
                insert_diy_project_project_items(objects: 
                    {
                    item_name: "${value.item_name}",
                    projectid: ${value.projectid},
                    qty: ${value.qty},
                    unit: "${value.unit}",
                    project_item_links: {
                        data: {
                            countryid: "${value.project_item_links.data.countryid}",
                            ecom_link: "${value.project_item_links.data.ecom_link}",
                            ecom_source: "${value.project_item_links.data.ecom_source}"
                        }
                        },
                       
                    }, on_conflict: {
                    constraint: project_items_pkey,
                    update_columns: itemid
                }) {
                    returning {
                        itemid
                    }
                }
            }`;
            // on_conflict: {
            //     constraint: project_item_links_pkey,
            //     update_columns: countryid
            // }
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_PROJECT_ITEM, "error in building query for CreateProjectItem api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.CREATE_PROJECT_ITEM, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_PROJECT_ITEM, "error in getting Response from CreateProjectItem api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}