import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiUpdateProjectItemLinks {
    static buildRequest(value,projectid) {
        let returnValue = null;
        try {
            if (!value) throw new Error('Error value not found')
            let query = gql `mutation insert_diy_project {
                update_diy_project_project_item_links(where: {itemid: {_eq: ${value.itemid}}, linkid: {_eq: ${value.linkid}}}, 
                  _set: {
                    ecom_link: "${value.ecom_link}",
                    ecom_source: "${value.ecom_source}",
                    countryid: "${value.countryid}"}) {
                  affected_rows
                  returning {
                    linkid
                  }
                }
              }`;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_PROJECT_ITEM_LINK, "error in building query for UpdateProjectItemLink api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.UPDATE_PROJECT_ITEM_LINK, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_PROJECT_ITEM_LINK, "error in getting Response from UpdateProjectItemLink api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}